import {
  post,
  del,
  put,
  get
} from "@/axios/index";

let _SERVICE_HOST =
  window.location.protocol + "//" + window.location.host + "/proxyApi";
if (process.env.NODE_ENV == "production") {
  _SERVICE_HOST =
    window.location.protocol + "//" + window.location.host + "/qyedu";
}

export const SERVICE_HOST = _SERVICE_HOST;

/**
 * example
 * 
 * ******* define *******
    export const example = {
        post: (params) => post('url', params),
        del: (params) => del('url', { data: params }),
        put: (params) => put('url', params),
        get: (params) => get('url', params)
    }
 * 
 * ******* use *******
    import { example } from '@/api/api'
    example.get()
*/

export const LoginApi = {
  sign: (params) => post(`${SERVICE_HOST}/sign-in`, params),
};

export const SmsApi = {
  send: (params) => post(`${SERVICE_HOST}/send/verify/code`, params),
};

export const UserApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/users/${id}`, params),
  delete: (id) => del(`${SERVICE_HOST}/users/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/users/${id}`),
  query: (params) => get(`${SERVICE_HOST}/users`, params),
};

export const StudentApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/students/${id}`, params),
  delete: (id, params) => del(`${SERVICE_HOST}/students/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/students/${id}`),
  query: (params) => get(`${SERVICE_HOST}/students`, params),

  getCountData: (school, clazz) => get(`${SERVICE_HOST}/students/count-data?school=${school || 0}&clazz=${clazz || 0}`),

  batchCreate: (params) => post(`${SERVICE_HOST}/students/batchs`, params),
  checkAccountExits: (params) => post(`${SERVICE_HOST}/students/check`, params),
  setClazz: (id, clazz) => put(`${SERVICE_HOST}/students/${id}/clazzs?clazz=${clazz || 0}`),

  dashboard: (id) => get(`${SERVICE_HOST}/schools/${id}/students/dashboard`),
};

export const ChildApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/childs/${id}`, params),
  getInfo: (id) => get(`${SERVICE_HOST}/childs/${id}`),
}

export const FamilyApi = {
  edit: (id, params, forceBind = 0) => put(`${SERVICE_HOST}/childs/${id}/parents?forceBind=${forceBind}`, params),
  delete: (id) => del(`${SERVICE_HOST}/childs/parents/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/childs/parents/${id}`),
  query: (id, params) => get(`${SERVICE_HOST}/childs/${id}/parents`, params)
}

export const ParentApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/parents/${id}`, params),
  delete: (id, params) => del(`${SERVICE_HOST}/parents/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/parents/${id}`),
  query: (params) => get(`${SERVICE_HOST}/parents`, params),
  batchEdit: (child, params) => post(`${SERVICE_HOST}/childs/${child}/parents/batchs`, params),
};

export const EmployeeApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/employees/${id}`, params),
  delete: (id, params) => del(`${SERVICE_HOST}/employees/${id}`, {
    data: params
  }),
  resign: (id, params) => put(`${SERVICE_HOST}/employees/${id}/resign`, params),
  getInfo: (id) => get(`${SERVICE_HOST}/employees/${id}`),
  query: (params) => get(`${SERVICE_HOST}/employees`, params),
  batchCreate: (params) => post(`${SERVICE_HOST}/employees/batchs`, params),
  checkAccountExits: (params) =>
    post(`${SERVICE_HOST}/employees/check`, params),
};

export const SchoolApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/schools/${id}`, params),
  delete: (id) => del(`${SERVICE_HOST}/schools/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/schools/${id}`),
  query: (params) => get(`${SERVICE_HOST}/schools`, params),


  querySchoolYear: (id, params) => get(`${SERVICE_HOST}/school/${id}/years`, params),
};

export const ConfigApi = {
  editSchoolConfig: (id, params) =>
    put(`${SERVICE_HOST}/schools/${id}/configs`, params),
  schoolConfig: (id) => get(`${SERVICE_HOST}/schools/${id}/configs`),
  editUserConfig: (id, params) =>
    put(`${SERVICE_HOST}/users/${id}/configs`, params),
  userConfig: (id) => get(`${SERVICE_HOST}/users/${id}/configs`),
};

export const ClazzApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/clazzs/${id}`, params),
  delete: (id) => del(`${SERVICE_HOST}/clazzs/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/clazzs/${id}`),
  query: (id) => get(`${SERVICE_HOST}/schools/${id}/clazzs`),
  getStudents: (id) => get(`${SERVICE_HOST}/clazzs/${id}/students`),
  editStudents: (id, students) => put(`${SERVICE_HOST}/clazzs/${id}/students`, students),
  getClazzData: (id) => get(`${SERVICE_HOST}/clazzs/${id}/datas`),
  getMyClazzs: () => get(`${SERVICE_HOST}/my/clazzs`),
};

export const SchoolTagApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/schools/tags/${id}`, params),
  delete: (id) => del(`${SERVICE_HOST}/schools/tags/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/schools/tags/${id}`),
  query: (id) => get(`${SERVICE_HOST}/schools/${id}/tags`),
  editStudentTag: (student, tags) =>
    put(`${SERVICE_HOST}/students/${student}/tags`, tags),
  getStudentTag: (student) => get(`${SERVICE_HOST}/students/${student}/tags`),
};

export const ModuleRoleApi = {
  setSuperAdminRole: (params) => put(`${SERVICE_HOST}/schools/admins`, params),
  setSchoolRole: (id, params) =>
    put(`${SERVICE_HOST}/schools/${id}/roles`, params),
  getEmployeesRoles: (id) =>
    get(`${SERVICE_HOST}/schools/employees/${id}/roles`),
  getSchoolRoles: (id, params) =>
    get(`${SERVICE_HOST}/schools/${id}/roles`, params),
  removeSchoolRole: (params) =>
    del(`${SERVICE_HOST}/schools/roles`, {
      data: params,
    }),
  demoteSchoolRole: (id) => put(`${SERVICE_HOST}/schools/roles/${id}`),
  getAdmins: (params) => get(`${SERVICE_HOST}/schools/admins`, params),
};

export const NotebookApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/notebooks/${id}`, params),
  delete: (id) => del(`${SERVICE_HOST}/notebooks/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/notebooks/${id}`),
  query: (params) => get(`${SERVICE_HOST}/notebooks`, params),
  getCount: () => get(`${SERVICE_HOST}/notebooks/counts`),
  batchEditTemplate: (params) =>
    put(`${SERVICE_HOST}/notebooks/templates`, params),
  deleteTemplate: (id) => del(`${SERVICE_HOST}/notebooks/templates${id}`),
  getTemplateInfo: (id) => get(`${SERVICE_HOST}/notebooks/templates${id}`),
  queryTemplates: (params) =>
    get(`${SERVICE_HOST}/notebooks/templates`, params),
};

export const WorkflowApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/workflows/${id}`, params),
  copy: (id) => post(`${SERVICE_HOST}/workflows/${id}/copy`),
  delete: (id) => del(`${SERVICE_HOST}/workflows/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/workflows/${id}`),
  getPreId: () => get(`${SERVICE_HOST}/workflows/preid`),
  query: (params) => get(`${SERVICE_HOST}/workflows`, params),
  batchEditTask: (id, params) =>
    post(`${SERVICE_HOST}/workflows/${id}/tasks`, params),
  editTask: (id, params) =>
    put(`${SERVICE_HOST}/workflows/tasks/${id}`, params),
  deleteTask: (id) => del(`${SERVICE_HOST}/workflows/tasks/${id}`),
  getTaskInfo: (id) => get(`${SERVICE_HOST}/workflows/tasks/${id}`),
  queryTasks: (params) => get(`${SERVICE_HOST}/workflows/tasks`, params),
  reply: (id, params) =>
    post(`${SERVICE_HOST}/workflows/tasks/${id}/replys`, params),
  deleteReply: (id) => del(`${SERVICE_HOST}/workflows/tasks/replys/${id}`),
  getTaskReply: (id, params) =>
    get(`${SERVICE_HOST}/workflows/tasks/${id}/replys`, params),
  attach: (id, file) =>
    post(`${SERVICE_HOST}/workflows/tasks/${id}/attachs`, {
      file: file,
    }),
  deleteAttach: (id) => del(`${SERVICE_HOST}/workflows/tasks/attachs/${id}`),
  getTaskAttach: (id, params) =>
    get(`${SERVICE_HOST}/workflows/tasks/${id}/attachs`, params),
};

export const CloudApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/cloud/objects/${id}`, params),
  delete: (id) => del(`${SERVICE_HOST}/cloud/objects/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/cloud/objects/${id}`),
  query: (params) => get(`${SERVICE_HOST}/cloud/objects`, params),
  getShareObjects: () => get(`${SERVICE_HOST}/cloud/share`),
  option: (params) => put(`${SERVICE_HOST}/cloud/objects/options`, params),
  getObjectShareInfo: (id) => get(`${SERVICE_HOST}/cloud/objects/${id}/share`),
  editObjectShare: (id, params) =>
    put(`${SERVICE_HOST}/cloud/objects/${id}/share`, params),
};
export const CloudTagApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/cloud/tags/${id}`, params),
  delete: (id) => del(`${SERVICE_HOST}/cloud/tags/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/cloud/tags/${id}`),
  query: (params) => get(`${SERVICE_HOST}/cloud/tags`, params),
};

export const WorkbenchApi = {
  listSchoolWorkbench: (id) => get(`${SERVICE_HOST}/schools/${id}/workbenchs`),
  getInfo: (id) => get(`${SERVICE_HOST}/schools/workbenchs/${id}`),
  edit: (id, params) => put(`${SERVICE_HOST}/schools/workbenchs/${id}`, params),
};

export const NoticeApi = {
  query: (params) => get(`${SERVICE_HOST}/notices`, params),
  getInfo: (id) => get(`${SERVICE_HOST}/notices/${id}`),
  getUnreadCount: () => get(`${SERVICE_HOST}/notices/unread_counts`),
  read: (params) => put(`${SERVICE_HOST}/notices/read`, params),
};

export const TingkeApi = {
  addAndEdit: (id, params) =>
    put(`${SERVICE_HOST}/lectures/tasks/${id}`, params),
  queryList: (params) => get(`${SERVICE_HOST}/lectures/tasks`, params),
  queryItem: (id) => get(`${SERVICE_HOST}/lectures/tasks/${id}`),
  delItem: (id) => del(`${SERVICE_HOST}/lectures/tasks/${id}`),
  CompleteThetask: (id, params) =>
    put(`${SERVICE_HOST}/lectures/tasks/${id}/finished`, params),
  deleteTask: (id) => del(`${SERVICE_HOST}/lectures/tasks/${id}`),
};

export const ReportsApi = {
  delReports: (id) => del(`${SERVICE_HOST}/lectures/tasks/reports/${id}`),
  queryReportsList: (id, params) =>
    get(`${SERVICE_HOST}/lectures/tasks/${id}/reports`, params),
  queryReportsItem: (id) => get(`${SERVICE_HOST}/lectures/tasks/reports/${id}`),
  postReports: (id, params) =>
    post(`${SERVICE_HOST}/lectures/tasks/${id}/reports`, params),
  queryReplys: (id, params) =>
    get(`${SERVICE_HOST}/lectures/tasks/records/${id}/replys`, params),
  cyAdd: (id, params) =>
    put(`${SERVICE_HOST}/lectures/tasks/${id}/reporter`, params),
};

export const DashboardApi = {
  queryDashboard: (params) =>
    get(`${SERVICE_HOST}/lectures/tasks/dashboard`, params),
};

export const CalendarApi = {
  getList: (id, params) => get(`${SERVICE_HOST}/calendar/events`, params),
  getDetails: (id, params) =>
    get(`${SERVICE_HOST}/calendar/events/${id}`, params),
  editInfo: (id, params) =>
    put(`${SERVICE_HOST}/calendar/events/${id}`, params),
  delInfo: (id, params) => del(`${SERVICE_HOST}/calendar/events/${id}`, params),
  delfile: (id, params) =>
    del(`${SERVICE_HOST}/calendar/events/annexes/${id}`, params),
  userEventShow: (id, params) =>
    get(`${SERVICE_HOST}/employee/${id}/records`, params),
};

export const EmployeefileApi = {
  userList: (params) => get(`${SERVICE_HOST}/employees`, params),
  resignUserList: (params) =>
    get(`${SERVICE_HOST}/employees/resign/records`, params),
  getDashboardData: (id) => get(`${SERVICE_HOST}/schools/${id}/employees/datas`),
  userDetails: (id, params) => get(`${SERVICE_HOST}/employees/${id}`, params),
  userModify: (id, params) => put(`${SERVICE_HOST}/employees/${id}`, params),
  userEdit: (id, params) => put(`${SERVICE_HOST}/employees/${id}/base`, params),
  userDel: (id, params) => del(`${SERVICE_HOST}/employees/${id}`, params),
  restoration: (id) => put(`${SERVICE_HOST}/employees/${id}/restoration`),
  batchAdd: (id, params) => post(`${SERVICE_HOST}/employees/batchs`, params),
  getList: (id, params) => post(`${SERVICE_HOST}/employees/check`, params),
  proveList: (id, params) =>
    get(`${SERVICE_HOST}/employees/${id}/certs`, params),
  proveModify: (id, certId, params) =>
    put(`${SERVICE_HOST}/employees/${id}/certs/${certId}/records`, params),
  proveOnlyRecord: (id, certId) =>
    get(`${SERVICE_HOST}/employees/${id}/certs/${certId}`),
  proveEdit: (id, certId, params) =>
    put(`${SERVICE_HOST}/employees/${id}/certs/${certId}`, params),
  proveDel: (id, params, certId) =>
    del(`${SERVICE_HOST}/employees/${id}/certs/${certId}`, params),
  proveOtherList: (id, params) =>
    get(`${SERVICE_HOST}/employees/${id}/other/certs`, params),
  proveDetails: (certId, params) =>
    get(`${SERVICE_HOST}/employees/certs/${certId}`, params),
  proveDelRecord: (certId, params) =>
    del(`${SERVICE_HOST}/employees/certs/records/${certId}`, params),
  //名单详情首页
  rosterDetailHome: (id, params) =>
    get(`${SERVICE_HOST}/employees/${id}/headInfo`, params),
  //名单详情基本信息
  rosterDetailInfo: (id, params) =>
    get(`${SERVICE_HOST}/employees/${id}/allInfo`, params),
  rosterDetailInfoEdit: (id, params) =>
    put(`${SERVICE_HOST}/employees/${id}/allInfo`, params),
  //名单详情合同
  rosterDetailContract: (id, params) =>
    get(`${SERVICE_HOST}/employee/${id}/contract`, params),

  rosterDetailContractDetail: (id, params) =>
    get(`${SERVICE_HOST}/employee/contract/${id}`, params),
  rosterDetailContractEdit: (id, params) =>
    put(`${SERVICE_HOST}/employee/contract/${id}`, params),
  rosterDetailContractDel: (id, params) =>
    del(`${SERVICE_HOST}/employee/contract/${id}`, params),

  //员工详情 - 其他记录API
  editOtherRecord: (id, params) => put(`${SERVICE_HOST}/employees/other-records/${id}`, params),
  getOtherRecord: (id) => get(`${SERVICE_HOST}/employees/other-records/${id}`),
  deleteOtherRecord: (id) => del(`${SERVICE_HOST}/employees/other-records/${id}`),
  queryOtherRecords: (params) => get(`${SERVICE_HOST}/employees/other-records`, params),
  getOtherRecordAnnexs: (id) => get(`${SERVICE_HOST}/employees/other-records/${id}/annexs`),

  //员工加班记录
  memberOvertimeRecord: (id, params) =>
    get(
      `${SERVICE_HOST}/employeesExt/employee/${id}/holidays/overtimes/records`,
      params
    ),
  memberOvertimeRecordEdit: (id, params) =>
    put(`${SERVICE_HOST}/employeesExt/overtimes/batchs/${id}`, params),
  memberOvertimeRecordDel: (id, params) =>
    del(`${SERVICE_HOST}/overtimes/records/${id}`, params),
  //薪酬相关接口
  payrollEditRecord: (id, params) =>
    get(`${SERVICE_HOST}/employee/${id}/payroll/records`, params),
  userPayrollDetails: (id, params) =>
    get(`${SERVICE_HOST}/employees/${id}/payroll/info`, params),
  //离职删除员工
  departUserDel: (id) => del(`${SERVICE_HOST}/employees/${id}`),
  //预约离职
  reservationDepart: (id, params) => put(`${SERVICE_HOST}/employees/${id}/preresign`, params),
  //操作离职
  operateDepart: (id, params) => put(`${SERVICE_HOST}/employees/${id}/resign`, params),
  //恢复在职
  restoreEntry: (id, params) => put(`${SERVICE_HOST}/employees/${id}/restoration`, params),
  //自动添加提醒
  autoRemind: (id, params) => get(`${SERVICE_HOST}/holidays/records/reminder`, params),
  //重要假期修改记录
  importantVacationRecords: (id, params) => get(`${SERVICE_HOST}/employee/${id}/holidays/records/modify`, params),

  //使用假期历史记录
  useVacationRecords: (id, params) => get(`${SERVICE_HOST}/employeesExt/employee/${id}/holidays/apply/records`, params),
  userAttendanceInfo: (id, params) => get(`${SERVICE_HOST}/employeesExt/employee/${id}/previews`, params),
  getUserHolidays: (id, params) => get(`${SERVICE_HOST}/employee/${id}/surplusHolidays`, params),
  getHolidayRealizationlist: (id, params) => get(`${SERVICE_HOST}/holidays/discount/info`, params),
  batchRealizationlist: (id, params) => get(`${SERVICE_HOST}/holidays/records/discount/batch`, params),
  //日历有事件
  calendarEvent: (id, params) => get(`${SERVICE_HOST}/employee/${id}/calendar/Info`, params),
  attendanceShow: (id, params) => get(`${SERVICE_HOST}/employee/${id}/attendance`, params),
  getCertDetails: (id, certId, params) => get(`${SERVICE_HOST}/employees/${id}/certs/${certId}/records`, params),
};

export const AttendanceApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/hr/attendances/${id}`, params),
  getInfo: (id) => get(`${SERVICE_HOST}/hr/attendances/${id}`),
  delete: (id) => del(`${SERVICE_HOST}/hr/attendances/${id}`),
  query: (params) => get(`${SERVICE_HOST}/hr/attendances`, params),
  approval: (id, params) => put(`${SERVICE_HOST}/hr/attendances/${id}/approval`, params),
  disuse: (id) => put(`${SERVICE_HOST}/hr/attendances/${id}/disuse`),
  getAnnexs: (id) => get(`${SERVICE_HOST}/hr/attendances/${id}/annexs`),
  getDashboardData: (id, params) => get(`${SERVICE_HOST}/school/${id}/hr/attendances/dashboard`, params),
  getEmployeeDashboardData: (id, params) => get(`${SERVICE_HOST}/hr/employees/${id}/attendances/dashboard`, params),

  historys: (params) => get(`${SERVICE_HOST}/hr/attendances/historys`, params),
  resetHistorys: (params) => put(`${SERVICE_HOST}/hr/attendances/historys/reset`, params),

  import: (school, params) => post(`${SERVICE_HOST}/school/${school}/hr/clock-in/import`, params),
  queryClockInRecord: (employee, params) => get(`${SERVICE_HOST}/employees/${employee}/hr/clock-in`, params),

  editWorkOvertime: (id, params) => put(`${SERVICE_HOST}/hr/work-overtimes/${id}`, params),
  getWorkOvertimeInfo: (id) => get(`${SERVICE_HOST}/hr/work-overtimes/${id}`),
  deleteWorkOvertime: (id) => del(`${SERVICE_HOST}/hr/work-overtimes/${id}`),
  queryWorkOvertimes: (params) => get(`${SERVICE_HOST}/hr/work-overtimes`, params),
  getWorkOvertimeAnnexs: (id) => get(`${SERVICE_HOST}/hr/work-overtimes/${id}/annexs`),

  editHolidayDiscount: (id, params) => put(`${SERVICE_HOST}/hr/holiday-discounts/${id}`, params),
  batchAddHolidayDiscount: (params) => put(`${SERVICE_HOST}/hr/holiday-discounts`, params),
  getHolidayDiscountInfo: (id) => get(`${SERVICE_HOST}/hr/holiday-discounts/${id}`),
  deleteHolidayDiscount: (id) => del(`${SERVICE_HOST}/hr/holiday-discounts/${id}`),
  queryHolidayDiscounts: (params) => get(`${SERVICE_HOST}/hr/holiday-discounts/recommend`, params),

  importFromMKHR: (params) => get(`${SERVICE_HOST}/hr/attendance/import`, params),
}

export const HolidayApi = {
  edit: (params) => put(`${SERVICE_HOST}/hr/employees/holidays`, params),
  getHoliday: (id, type) => get(`${SERVICE_HOST}/hr/employees/${id}/holidays?type=${type}`),
  getHolidays: (id) => get(`${SERVICE_HOST}/hr/employees/${id}/holidays/list`),
  getHistorys: (id, params) => get(`${SERVICE_HOST}/hr/employees/${id}/holidays/historys`, params),
  getHistoryAnnexs: (id) => get(`${SERVICE_HOST}/hr/employees/holidays/historys/${id}/annexs`)
}

export const SalarySettingApi = {
  edit: (employee, params) => put(`${SERVICE_HOST}/hr/employees/${employee}/salarys/setting`, params),
  getInfo: (id) => get(`${SERVICE_HOST}/hr/employees/salarys/setting/${id}`),
  getInfoByEmployee: (employee) => get(`${SERVICE_HOST}/hr/employees/${employee}/salarys/setting`),
  delete: (id) => del(`${SERVICE_HOST}/hr/employees/salarys/setting/${id}`),
  query: (params) => get(`${SERVICE_HOST}/hr/salarys/setting`, params)
}

export const SubsidiesApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/hr/subsidies/${id}`, params),
  getInfo: (id) => get(`${SERVICE_HOST}/hr/subsidies/${id}`),
  delete: (id) => del(`${SERVICE_HOST}/hr/subsidies/${id}`),
  query: (params) => get(`${SERVICE_HOST}/hr/subsidies`, params),
  getAnnexs: (id) => get(`${SERVICE_HOST}/hr/subsidies/${id}/annexs`),
}

export const ContractApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/hr/salarys/contracts/${id}`, params),
  getInfo: (id) => get(`${SERVICE_HOST}/hr/salarys/contracts/${id}`),
  delete: (id) => del(`${SERVICE_HOST}/hr/salarys/contracts/${id}`),
  query: (params) => get(`${SERVICE_HOST}/hr/salarys/contracts`, params),
  getAnnexs: (id) => get(`${SERVICE_HOST}/hr/salarys/contracts/${id}/annexs`),
}

export const PayrollApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/hr/payrolls/${id}`, params),
  getInfo: (id) => get(`${SERVICE_HOST}/hr/payrolls/${id}`),
  delete: (id) => del(`${SERVICE_HOST}/hr/payrolls/${id}`),
  query: (school, params) => get(`${SERVICE_HOST}/schools/${school}/hr/payrolls`, params),
  generate: (id) => put(`${SERVICE_HOST}/hr/payrolls/${id}/generate`),
  async: (id) => put(`${SERVICE_HOST}/hr/payrolls/${id}/async`),

  editRecord: (id, params) => put(`${SERVICE_HOST}/hr/payrolls/records/${id}`, params),
  getRecordInfo: (id) => get(`${SERVICE_HOST}/hr/payrolls/records/${id}`),
  deleteRecord: (id) => del(`${SERVICE_HOST}/hr/payrolls/records/${id}`),
  queryRecord: (params) => get(`${SERVICE_HOST}/hr/payrolls/records`, params),
  recordLock: (id) => put(`${SERVICE_HOST}/hr/payrolls/records/${id}/lock`),

  attendances: (params) => get(`${SERVICE_HOST}/hr/payrolls/attendances`, params),
  editAttendance: (id, params) => put(`${SERVICE_HOST}/hr/payrolls/attendances/${id}`, params),

  recordModify: (id, params) => post(`${SERVICE_HOST}/hr/payrolls/records/${id}/modify`, params),
  queryModifyHisoty: (params) => get(`${SERVICE_HOST}/hr/payrolls/records/historys`, params),
  getModifyAnnexs: (id) => get(`${SERVICE_HOST}/hr/payrolls/records/historys/${id}/annexs`),

  dashboard: (school, params) => get(`${SERVICE_HOST}/schools/${school}/hr/payrolls/dashboard`, params),
}

export const YearEndBonusApi = {
  batchSave: (body) => put(`${SERVICE_HOST}/hr/year-end-bonus`, body),
  edit: (id, params) => put(`${SERVICE_HOST}/hr/year-end-bonus/${id}`, params),
  getInfo: (id) => get(`${SERVICE_HOST}/hr/year-end-bonus/${id}`),
  delete: (id) => del(`${SERVICE_HOST}/hr/year-end-bonus/${id}`),
  query: (params) => get(`${SERVICE_HOST}/hr/year-end-bonus`, params)
}



export const attendanceApi = {
  getVacationTypeBalance: (id, params, type) =>
    get(`${SERVICE_HOST}/employeesExt/employee/${id}/type/${type}`, params),
  attendanceTips: (id, params) =>
    get(`${SERVICE_HOST}/attendanceMgr/school/${id}/noWorkTimeCount`, params),
  addDepartMentHead: (id, params) =>
    put(`${SERVICE_HOST}/employeesExt/departmentHead`, params),
  attendanceAccessList: (id, params) =>
    get(`${SERVICE_HOST}/employeesExt/employeesCheckInOut`, params),
  editMemberVacation: (id, params) =>
    put(`${SERVICE_HOST}/employeesExt/holiday/base/batchs/${id}`, params),

  getSchoolMemberData: (id, params) =>
    get(`${SERVICE_HOST}/employeesExt/school/${id}/employees/datas`, params),

  attendanceHome: (id, params) =>
    get(
      `${SERVICE_HOST}/attendanceMgr/school/${id}/getAttendanceSummary`,
      params
    ),
  //审批记录

  attendanceApprovalList: (id, params) =>
    get(`${SERVICE_HOST}/employeesExt/holidays/apply/records`, params),
  attendanceSubmitshare: (id, params) =>
    get(`${SERVICE_HOST}/attendanceMgr/school/${id}/getDeptShare`, params),
  attendanceExport: (id, params) =>
    post(`${SERVICE_HOST}/attendanceMgr/school/${id}/import`, params),
  attendanceExportList: (id) =>
    get(`${SERVICE_HOST}/attendanceMgr/school/${id}/getAttendanceHistory`),
  getuserTag: (id, params) =>
    get(`${SERVICE_HOST}/attendanceMgr/school/${id}/tags`, params),
  attendanceList: (id, params) =>
    get(`${SERVICE_HOST}/attendanceMgr/school/${id}/getAttendanceList`, params),
  resetNews: (id, params) =>
    get(
      `${SERVICE_HOST}/attendanceMgr/school/${id}/resetAttendanceException`,
      params
    ),
  attendanceShare: (id, params) =>
    get(
      `${SERVICE_HOST}/attendanceMgr/school/${id}/getDeptEmployeeCountList`,
      params
    ),
  addAttendanceList: (params) =>
    put(`${SERVICE_HOST}/attendanceMgr/leave/edit`, params),
  // getuserTag

  getSchoolYearInfo: (id, params) =>
    get(`${SERVICE_HOST}/school/${id}/years`, params),
  getYearInfo: (id, params) =>
    get(`${SERVICE_HOST}/school/years/${id}`, params),
  editYearInfo: (id, params) =>
    put(`${SERVICE_HOST}/school/years/${id}`, params),
  delYearInfo: (id, params) =>
    del(`${SERVICE_HOST}/school/years/${id}`, params),
  getWorkday: (id, params) =>
    get(`${SERVICE_HOST}/school/${id}/workdays`, params),
  setWorkday: (id, params) =>
    put(`${SERVICE_HOST}/school/${id}/workdays`, params),
  getStaffWorktime: (id, params) =>
    get(`${SERVICE_HOST}/employees/${id}/worktimes`, params),
  setStaffWorktime: (id, params) =>
    put(`${SERVICE_HOST}/employees/${id}/worktimes`, params),
  getSchooldeptWorktime: (id, params) =>
    get(`${SERVICE_HOST}/school/${id}/depts/worktimes`, params),
  getdeptWorktime: (id, params) =>
    get(`${SERVICE_HOST}/school/depts/${id}/worktimes`, params),
  setdeptWorktime: (id, params) =>
    put(`${SERVICE_HOST}/school/depts/${id}/worktimes`, params),
  getAttendancelist: (id, params) =>
    get(`${SERVICE_HOST}/emplyees/attendances/overviews`, params),
  getStaffattendances: (id, params) =>
    get(`${SERVICE_HOST}/emplyees/${id}/attendances/overviews`, params),
  recordList: (id, params) =>
    get(
      `${SERVICE_HOST}/employeesExt/employee/${id}/holidays/base/records`,
      params
    ),
  recordDetail: (id, params) =>
    get(`${SERVICE_HOST}/holidays/records/${id}`, params),
  rosterDetailHomeAskforleave: (id, params) =>
    put(`${SERVICE_HOST}/employeesExt/holiday/apply/batchs/${id}`, params),
  recordDel: (id, params) =>
    del(`${SERVICE_HOST}/holidays/records/${id}`, params),
};

export const _PayrollApi = {
  //工资单相关api

  //工资单补贴记录相关api
  subsidyRecordDetail: (id, params) =>
    get(`${SERVICE_HOST}/payroll/subsidy/records/${id}`, params),
  subsidyRecordEdit: (id, params) =>
    put(`${SERVICE_HOST}/payroll/subsidy/records/${id}`, params),
  subsidyRecordDel: (id, params) =>
    del(`${SERVICE_HOST}/payroll/subsidy/records/${id}`, params),
  subsidyRecordList: (id, params) =>
    get(`${SERVICE_HOST}/payroll/subsidy/records/lists`, params),
  //工资单记录相关api
  recordList: (id, params) =>
    get(`${SERVICE_HOST}/payroll/records/${id}`, params),
  recordEdit: (id, params) =>
    put(`${SERVICE_HOST}/payroll/records/${id}`, params),
  recordDel: (id, params) =>
    del(`${SERVICE_HOST}/payroll/records/${id}`, params),
  recordDetail: (id, params) =>
    get(`${SERVICE_HOST}/payroll/records/lists`, params),
  //工资单配置相关api
  configDetail: (id, params) => get(`${SERVICE_HOST}/payroll/config/${id}`),
  configEdit: (id, params) => put(`${SERVICE_HOST}/payroll/config/${id}`),
  configDel: (id, params) => del(`${SERVICE_HOST}/payroll/config/${id}`),
  configList: (id, params) => get(`${SERVICE_HOST}/payroll/config/lists`),
  //考勤明细相关api
  attendanceGetDetail: (id, params) =>
    get(`${SERVICE_HOST}/payroll/detail/${id}`),
  attendancePutDetail: (id, params) =>
    put(`${SERVICE_HOST}/payroll/detail/${id}`),
  attendanceDelDetail: (id, params) =>
    del(`${SERVICE_HOST}/payroll/detail/${id}`),
  attendanceGetList: (id, params) =>
    get(`${SERVICE_HOST}/payroll/detail/lists`),
  //考勤记录相关api
  attendanceRecordDetail: (id, params) =>
    get(`${SERVICE_HOST}/payroll/attendance/${id}`),
  attendanceRecordEdit: (id, params) =>
    put(`${SERVICE_HOST}/payroll/attendance/${id}`),
  attendanceRecordDel: (id, params) =>
    del(`${SERVICE_HOST}/payroll/attendance/${id}`),
  attendanceRecordList: (id, params) =>
    get(`${SERVICE_HOST}/payroll/attendance/lists`),
};

export const RecruitmentApi = {
  edit: (id, params) => put(`${SERVICE_HOST}/hr/recruitment/requirements/${id}`, params),
  delete: (id) => del(`${SERVICE_HOST}/hr/recruitment/requirements/${id}`),
  getInfo: (id) => get(`${SERVICE_HOST}/hr/recruitment/requirements/${id}`),
  query: (params) => get(`${SERVICE_HOST}/hr/recruitment/requirements`, params),

  editApply: (id, params) => put(`${SERVICE_HOST}/hr/recruitment/applys/${id}`, params),
  deleteApply: (id) => del(`${SERVICE_HOST}/hr/recruitment/applys/${id}`),
  getApplyInfo: (id) => get(`${SERVICE_HOST}/hr/recruitment/applys/${id}`),
  queryApplys: (params) => get(`${SERVICE_HOST}/hr/recruitment/applys`, params),

  editInterview: (id, params) => put(`${SERVICE_HOST}/hr/recruitment/interviews/${id}`, params),
  deleteInterview: (id) => del(`${SERVICE_HOST}/hr/recruitment/interviews/${id}`),
  getInterviewInfo: (id) => get(`${SERVICE_HOST}/hr/recruitment/interviews/${id}`),
  queryInterviews: (params) => get(`${SERVICE_HOST}/hr/recruitment/interviews`, params),

  editInterviewer: (id, params) => put(`${SERVICE_HOST}/hr/recruitment/interviewers/${id}`, params),
  getInterviewerInfo: (id) => get(`${SERVICE_HOST}/hr/recruitment/interviewers/${id}`),

}

export const CommonApi = {
  proveLibraryList: (id, params) => get(`${SERVICE_HOST}/certs`, params),
  proveLibraryDetails: (id, params) =>
    get(`${SERVICE_HOST}/certs/${id}`, params),
  proveLibraryModify: (id, params) =>
    put(`${SERVICE_HOST}/certs/${id}`, params),
  proveLibraryDel: (id, params) => del(`${SERVICE_HOST}/certs/${id}`, params),

  deparmentDetails: (id, params) => get(`${SERVICE_HOST}/depts/${id}`, params),
  deparmentList: (id, params) => get(`${SERVICE_HOST}/depts`, params),
  deparmentEdit: (id, params) => put(`${SERVICE_HOST}/depts/${id}`, params),
  deparmentDel: (id, params) => del(`${SERVICE_HOST}/depts/${id}`, params),

  dp_proveList: (id, params) => get(`${SERVICE_HOST}/depts/certs`, params),
  dp_proveDetails: (id, params) =>
    get(`${SERVICE_HOST}/depts/${id}/certs`, params),
  dp_proveEdit: (id, params) =>
    put(`${SERVICE_HOST}/depts/certs/${id}`, params),
  dp_proveDel: (id, params) => del(`${SERVICE_HOST}/depts/certs/${id}`, params),
  batchDeparmentEdit: (id, params) =>
    put(`${SERVICE_HOST}/depts/${id}/certs`, params),
  countryList: (id, params) => get(`${SERVICE_HOST}/countrys`, params),
};